/*
aww - average weekly wage
saww - standard average weekly wage
half_saww - saww / 2
*/

const SAWW = 1714;
const HALF_SAWW = SAWW / 2;
const MAX_BENEFIT = 1542;

const formatValue = (value) => parseFloat(value.toString().replace(/,/g, ""));

export function hourlyFixedToQuarterly(wage, hours) {
  const weeks = 13;
  return formatValue(wage) * formatValue(hours) * weeks;
}

export function yearlyToQuarterly(annual) {
  if (!annual) return 0;
  return formatValue(annual) / 4;
}

export function topQuarterlyToQuarterly(income) {
  if (!income) return 0;
  return formatValue(income) / 2;
}

export function quarterlyToWeeklyBenefits(quarterly) {
  const semiAnnual = formatValue(quarterly) * 2;
  const aww = Math.floor(semiAnnual / 26);

  if (aww < 100) return aww;
  if (aww >= 1.4 * SAWW) return MAX_BENEFIT;
  if (aww <= HALF_SAWW) {
    return Math.max(100, Math.floor(aww * 0.9));
  } else {
    return Math.floor(HALF_SAWW * 0.9 + 0.5 * (aww - HALF_SAWW));
  }
}

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});
