import React from "react";

export default {
  en: {
    label: {
      back: `Back`,
      next: `Next`,
      estimate: `Estimate`,
      option: `Option`,
      restart: `Start over`,
    },
    stepOne: {
      title: `I am:`,
      radioOptions: [
        {
          value: "hourlyFixed",
          label: `Paid an hourly wage, and I earn about the same amount of money
            every week`,
        },
        {
          value: "hourlyVariable",
          label: `Paid an hourly wage, and the amount I earn changes a lot from
            week to week or season to season`,
        },
        {
          value: "salary",
          label: "Paid a salary",
        },
        {
          value: "self",
          label: "Self-employed",
        },
      ],
    },
    stepTwo: {
      title: {
        hourlyFixed: `Please complete one the following:`,
        hourlyVariable: (
          <>
            <p>
              Since the number of hours you work changes from week to week or
              season to season, your weekly pay will be based on the times of
              year when you earned the most money.
            </p>
            <p>
              To fill out the form, use information from the quarters when you
              earned the most money in the last year. The quarters the state
              uses are: January to March, April to June, July to September and
              October to December.
            </p>
          </>
        ),
        salary: `Please complete the following:`,
        self: `Please complete the following:`,
      },
      totalInputPre: `In total, I earned`,
      totalInputPost: {
        hourlyVariable: `in my top two quarters in the last year.`,
        fallback: `in the last year.`,
      },
      wageInputPre: `My hourly wage is`,
      hoursInputPre: `On average, I worked`,
      hoursInputPost: `hours per week in the last year.`,
      hourlyFixedNote: `If you have worked for more than one employer in the
        last year, enter the total amount you’ve earned in the past year.`,
    },
    stepThree: {
      title: `Based on the information you entered,
        your estimated weekly pay would be`,
      subtitle: `This estimate is based on applications received in 2025, for leave starting in 2025.`,
      learnMoreLinkText: {
        self: `Learn more about opting in`,
        other: `Learn more about Paid Leave`,
      },
      selfEmployedNote: `As a self-employed person,
        you have to opt in to Paid Family and Medical Leave.`,
      disclaimer: `The payment amounts generated by this calculator are estimates only.
        This calculator uses the 2025 maximum benefit amount and state average weekly wage.
        Your actual payment amount will be calculated after you apply and qualify for paid leave.`,
    },
  },
  es: {
    label: {
      back: `Atrás`,
      next: `Siguiente`,
      estimate: `Calcular`,
      option: `Opción`,
      restart: `Comenzar de nuevo`,
    },
    stepOne: {
      title: `Mi situación laboral es como sigue:`,
      radioOptions: [
        {
          value: "hourlyFixed",
          label: `Me pagan por hora de trabajo y
            gano más o menos lo mismo cada semana`,
        },
        {
          value: "hourlyVariable",
          label: `Me pagan por hora de trabajo y
            la cantidad de dinero que gano es muy diferente`,
        },
        {
          value: "salary",
          label: "Recibo un sueldo fijo",
        },
        {
          value: "self",
          label: "Trabajo por mi cuenta",
        },
      ],
    },
    stepTwo: {
      title: {
        hourlyFixed: `Complete una de las siguientes respuestas:`,
        hourlyVariable: (
          <>
            <p>
              Dado que la cantidad de horas que trabaja cambia de una semana a
              otra o de una temporada a otra, su pago semanal se basará en las
              épocas del año en las que ganó más dinero.
            </p>
            <p>
              Para llenar el formulario, use la información de los trimestres en
              los que tuvo el sueldo más alto en el último año. El estado divide
              el año en cuatro trimestres: de enero a marzo, de abril a junio,
              de julio a septiembre y de octubre a diciembre.
            </p>
          </>
        ),
        salary: `Complete la siguiente respuesta:`,
        self: `Complete la siguiente respuesta:`,
      },
      totalInputPre: `En total, gané`,
      totalInputPost: {
        hourlyVariable: `en mis mejores dos trimestres del año pasado.`,
        fallback: `el año pasado.`,
      },
      wageInputPre: `Mi sueldo por hora es de`,
      hoursInputPre: `En promedio, trabajé`,
      hoursInputPost: `horas por semana el año pasado.`,
      hourlyFixedNote: `Si trabajó para más de un patrón el año pasado,
        escriba la cantidad total de dinero que ganó el año pasado.`,
    },
    stepThree: {
      title: `Con base en la información que compartió,
        su sueldo semanal estimado sería de`,
      subtitle: `Esta estimación se basa en las solicitudes que se recibieron
        en el 2025 para permisos a partir del 2025.`,
      learnMoreLinkText: {
        self: `Más información sobre cómo inscribirse`,
        other: `Más información sobre cómo obtener un permiso pagado`,
      },
      selfEmployedNote: `Como persona que trabaja por su cuenta, tiene la
        opción de inscribirse en el programa de Permiso de cuidado pagado.`,
      disclaimer: `Los montos de sueldo que resultaron en esta calculadora
        son solo estimaciones. Esta calculadora utiliza el monto máximo de
        beneficios de 2025 y el salario semanal promedio estatal. La cantidad
        de sueldo que recibirá se calculará después de que solicite su permiso
        pagado y sea aprobado.`,
    },
  },
};
